import React from 'react';

import css from './image.module.css';

function Image(props) {
  const { src, alt, caption } = props;

  return (
    <div className={css.imageWrapper}>
      <img src={src} alt={alt ? alt : caption} loading={'lazy'} />

      {caption && <p className={css.caption}>{caption}</p>}
    </div>
  );
}

export default Image;
