import React from 'react';
import css from './page-header.module.css';

function PageHeader({ title }) {
  return (
    <div className={css.pageHeader}>
      {title && <h1 className={css.title}>{title}</h1>}
    </div>
  );
}

export default PageHeader;
