import React from 'react';
import css from './embed.module.css';

function Embed({ text }) {
  const startIndex = text.indexOf('<iframe');
  const endIndex = text.indexOf('</iframe>') + '</iframe>'.length;
  const start = text.substring(0, startIndex);
  const embed = text.substring(startIndex, endIndex);
  const end = text.substring(endIndex);
  let html;

  if (
    embed.indexOf('twitch.tv') !== -1 ||
    embed.indexOf('youtube.com') !== -1 ||
    embed.indexOf('vimeo.com') !== -1
  ) {
    html = `${start}<div class=${css.responsiveEmbed}>${embed}</div>${end}`;
  } else {
    html = text;
  }

  return (
    <div className={css.wrapper} dangerouslySetInnerHTML={{ __html: html }} />
  );
}

export default Embed;
