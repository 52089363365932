import React from 'react';
import css from './page.module.css';

import PageHeader from '../PageHeader';

function Page({ title, body }) {
  return (
    <>
      {title && <PageHeader title={title} />}

      {body && <div className={css.body}>{body}</div>}
    </>
  );
}

export default Page;
