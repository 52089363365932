import React from 'react';
import css from './content.module.css';
import cn from 'classnames';
import { Link } from 'gatsby';
import { MARKS, BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Embed from '../Embed';
import Button from '../Button';
import Image from '../Image';

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const {
        data: {
          target: {
            sys: { contentType: { sys: { id } = {} } = {} } = {},
            fields: { label, url, newTab, align } = {}
          } = {}
        } = {}
      } = node;

      // If button entry
      if (id && id === 'button' && url && label) {
        let button;

        if (
          newTab &&
          (newTab['en-US'] === 'true' || newTab['en-US'] === true)
        ) {
          button = (
            <Button
              href={url['en-US']}
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              {label['en-US']}
            </Button>
          );
        } else {
          button = <Button href={url['en-US']}>{label['en-US']}</Button>;
        }

        return (
          <div
            className={cn(
              css.block,
              { [css.textCenter]: align && align['en-US'] === 'Center' },
              { [css.textRight]: align && align['en-US'] === 'Right' }
            )}
          >
            {button}
          </div>
        );
      }
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const {
        data: {
          target: {
            fields: {
              description: { ['en-US']: description } = {},
              file: { ['en-US']: { url } } = {}
            } = {}
          } = {}
        } = {}
      } = node;

      if (url) {
        return <Image src={url} caption={description} />;
      }
    },
    [INLINES.HYPERLINK]: node => {
      const { data: { uri } = {}, content: [{ value } = {}] = [] } = node;
      const absoluteUrl = /^https?:\/\/|^\/\//i;

      if (absoluteUrl.test(uri)) {
        return <a href={uri}>{value}</a>;
      }

      return <Link to={uri}>{value}</Link>;
    }
  },
  renderMark: {
    [MARKS.CODE]: text => {
      return <Embed text={text} />;
    }
  }
};

function Content({ json }) {
  const content = documentToReactComponents(json, options);

  return <div className={css.content}>{content}</div>;
}

export default Content;
