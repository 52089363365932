import React from 'react';
import { Helmet } from 'react-helmet';

function SEO({ title, description, image }) {
  const metaTitle = title ? `${title} - Eli Horowitz` : 'Eli Horowitz';
  const metaImage = 'https:' + image;

  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta property="twitter:title" content={metaTitle} />
      <meta property="og:title" content={metaTitle} />
      <meta property="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:image" content={metaImage} />
      <meta property="og:image" content={metaImage} />
      <meta property="og:image:secure_url" content={metaImage} />
    </Helmet>
  );
}

export default SEO;
