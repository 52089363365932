import React from 'react';
import css from './button.module.css';
import cn from 'classnames';

function Button({ href, rel, target, type, onClick, children, className }) {
  if (href) {
    return (
      <a
        href={href}
        rel={rel}
        target={target}
        onClick={onClick}
        className={cn(className, css.button)}
      >
        {children}
      </a>
    );
  }

  return (
    <button type={type} onClick={onClick} className={cn(className, css.button)}>
      {children}
    </button>
  );
}

export default Button;
